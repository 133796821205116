*{
  margin: 0px;
  padding: 0px;
}
.customShadow {
  box-shadow: 
      2px 2px 2px rgba(0, 0, 0, 0.1),  /* Bottom-right shadow */
      -2px -2px 2px rgba(0, 0, 0, 0.1), /* Top-left shadow */
      2px -2px 2px rgba(0, 0, 0, 0.1),  /* Top-right shadow */
      -2px 2px 2px rgba(0, 0, 0, 0.1),  /* Bottom-left shadow */
      4px 4px 4px rgba(0, 0, 0, 0.2),   /* Bottom-right shadow for more depth */
      -4px -4px 4px rgba(0, 0, 0, 0.2),  /* Top-left shadow for more depth */
      4px -4px 4px rgba(0, 0, 0, 0.2),   /* Top-right shadow for more depth */
      -4px 4px 4px rgba(0, 0, 0, 0.2);   /* Bottom-left shadow for more depth */
}
.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media only screen and (max-width:600px){
  .customShadow{
  box-shadow: none;
}
}
.provileAvtar img{
  height: 55px;
  width: 55px;
  border-radius: 50%;
  border: .5px dashed gray;
}
.editForm label{
  margin-bottom: 5px;
  margin-top: 15px;
}
.sideBar{
  width: 20%;
  background-color: whitesmoke;
}

@media (max-width: 500px) {
  .sideBar {
   right:0;
   position: absolute;
   width: 100%;
   z-index: 100;
   height: 100vh;
   
  } 
}